.testimonialSlide{
    width: 100%;
    min-height: 521px;
  
    /* background-color: red; */
    border-radius: 10px;
    overflow:hidden;
}
.testimonialSlide{
    opacity: 0.8;
    transform: scale(0.7);
    transition: 0.5s;
    display: flex !important;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}
.testimonialCarouselItem{
    max-width: 99%;
     background: rgba(89, 101, 175, 0.2);
    display: flex;
    align-items: center;
    padding: 60px 15px 15px 15px;
    border-radius: 20px;

}
.testimonialSlide.slide-active{
   opacity: 1;
   transform: scale(1.1);
}

.testimonialItemUser{
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    border: 5px solid #fff;
    top: 55px;
    left: 0;
    margin: 0 auto;
    right: 0;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
}
.testimonialItemUser img{
    width: 100%;
}
.testimonialItemContent{
    text-align: center;
}
.testimonialItemContent p{
    font-size: 14px;
    line-height: 17px;
}
.testimonialItemContent h5{
    color:#C62154;
}
.slick-dots{
    bottom: 30px !important;
}
.slick-dots li{
    width: unset;
    height: unset;
}
.slick-dots li button{
    width: 25px !important;
    height: 3px !important;
    background:rgba(89, 101, 175, 0.2) !important;
    display: block !important;
    padding: 0 !important;

}
.slick-dots li.slick-active button{
    background:#C62154 !important;
}

.slick-dots li button:before{
    content: unset !important;
}
.userName{
    font-size: 16px !important;
    color: #000;
    font-weight: 400;
    margin-bottom: 5px;
}
.userDesignation{
    font-size: 14px !important;
    color: #C62154;
    font-weight: 400;
    margin-bottom: 0px;
}
@media only screen and (max-width: 768px)
{
    .slick-dots {
    bottom: 0px !important;
}
}