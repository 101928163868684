@media only screen and (max-width: 768px) {
    /* .navList{
      display: none;
    } */
     .form-progress ul li{
        width: 29.5% !important;
    }
    .dateBtn {

    padding: 7px 10px;

}
    .navToggle{
     display: block;
     margin-left: 0;
    }
    .navigation{
        display: block;
        width: 300px;
        height: 100%;
        position: fixed;
        top: 0;
        right: -300px;
        left: auto;
        z-index: 1000;
        background: linear-gradient(225.27deg, #5567B0 4.65%, #AD549F 36.17%, #D03D6B 64.44%);
        transition: all 300ms ease-in;
        padding-top: 70px;
      }
      .navigation.open{
        right:0px;
      
      }
      .navList li{
        display: block;
      }
      .navList li a{
        color: #fff;
      }
      .navList li a:after{
        margin-top: 10px;
        height: 1px;
       
      }
      .navList li a.active:after{
        background: #fff;
        height: 1px;
      }
      .replyBtn{
    position: absolute;
    right:80px;
    width: 50px;
    height: 50px;
      }
    header{
        padding: 0px; 
     }
    .headerContainer {
        box-shadow: 5px 5px 15px rgb(0 0 0 / 10%);
    }

    .book-btn{
       font-size: 10px;
    }
    .designElement{
        display: none;
    }
    .aboutSection {
        width: 100%;
        margin-top: 50px;
    }
    .pfc{
        text-align: center;
    }
    .pfc img{
        border-radius: 35px;
    }
    .aboutContent{
        padding: 15px;
    }
    .ourPortfolio {
     background-image: unset;
        padding: 50px 0px;
        margin-top: 0px;
    }
    .pf-1, .pf-2, .pf-3, .pf-4{
        top: unset;
    }
    .pf-1, .pf-2, .pf-3{
        left: unset;
    }
    .portfolioIcons {
        display: flex;
        flex-direction: column;
    }
    .portfolioIconBox{
        text-align: center;
        max-width: 250px;
        margin: 15px auto;
    }
    .iconWrapper{
        margin: 0 auto;
    }
    .portfolioIconBox p{
        margin-top: 15px;
    }
    .aboutContent h1, .ourLearnings h1, .languageText h3, .faq h1, .internship h1{
        font-size: 35px;
    }
    .latestPost h1{
        font-size: 34px;
    }
    .ourLanguages h1, .video-title h1, .pioneersSectionContent h1{
        font-size: 29px;
    }
    .pioneersSectionContent h1{
        margin-top: 15px;
    }
    .bannerContent h1{
        font-size: 25px !important; 
    }
    .testimonial h1{
        font-size: 26px;
    }
    .ourPortfolio h1 {
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
    }
    .aboutContent h1{
        margin-top: 15px;
    }
    .ourLanguages, .ourLanguages2{
        height: auto;
       
    }
    .ourLanguages{
        margin-bottom: 50px;
    }
    .ourLanguages2 .languageImage{
        top: unset;
    }
    .designEle2{
        display: none;

    }
    .ourLanguages2{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .ourLanguages2 .languageText {
        padding-left: 0px;
        padding-top: 30px;
    }
    .ourLanguages .languageText{
      padding-bottom: 30px;
      padding-right: 0px;
    }
    .internship{
        background-image: unset;
    }
    .internshipList{
        flex-direction: column;
    }
    .internship h1{
        text-align: center;
    }
    .internshipItem {
        margin: 30px;
    }
    .footerTop{
        flex-direction:column;
        text-align: center;
    }
    .footerTop p {
        margin-bottom: 7px;
        margin-right: 0px;
        font-size: 20px;
    }
    .footerWidget1, .footerWidget2{
        padding: 0px;
    }
    .footerWidget2 h3 {
        font-size: 25px;
        margin-top: 30px;
    }
    .testimonialSlider{
        overflow: hidden;
    }
    .ourLearnings{
        padding: 0px 0px 50px 0px;
    }
    .ourLanguages{
        padding: 50px 0px;
    }
    .footerMiddle {
        padding: 50px 15px 20px;
    }
    .learningItems{
      flex-direction: column;
    }
    .blur{
        display: none;
    }
    .learningItemsContent {
        margin-left: 0px;
        z-index: 1;
        padding-top: 10px;
    }
    .testimonialCarouselItem{
        padding: 75px 12px 10px 12px !important;
    }
    .slick-dots {
        bottom: 0px !important;
    }
    .team-item {
        margin-bottom: 75px;
    }
    .innerDesignEle{
        display: none;
    }
    .contactForm{
        position: relative;
    }
    .positionRelative{
     padding-left: 15px;
     margin-top: 25px;

    }
    .innerPageBanner{
        padding-top: 10px;
        padding-bottom: 10px;

    }
    .latest-post{
        margin-top: 50px;
    }
    .latest-post h3 {
        font-size: 22px !important;
    }
    .post-title {
        line-height: 30px;
        font-size: 27px;
        margin-top: 15px;
    }

    .patchContainer{
        border-radius: 0px;
    }
    .whyAlzContent{
        padding: 15px;
    }
   
}