.accordionItem{
    margin-bottom: 10px;
   
}
.accordionItem{
    border:1px solid #D9D9D9;
    border-radius: 10px;
    overflow: hidden;
}
.accQues, .accQues button{
    width: 100%;
    text-align: left;
    border: none;
}

.accQues button{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    font-size: 18px;
    font-weight: 400;
    color: #373737;
}
.accQues button img{
    width: 20px;
    transition: all 300ms ease-in;
}
.accQues.active-item button{
    background: linear-gradient(225.27deg, #5567B0 4.65%, #AD549F 36.17%, #D03D6B 64.44%);
    color: #fff;
  
}
.accQues button .iconAcc{
    transition: all 300ms ease-in;
}
.accQues.active-item button .iconAcc{
   transform: rotate(180deg);
}

.accAns{
    opacity: 0;
    visibility:hidden;
    height: 0;
    transition: all 300ms ease-in;
    padding: 0px 10px;
    background-color: #fff;
}
.accAns.active-item{
    opacity: 1;
    visibility: visible;
    height: auto;
    padding: 15px 10px;
}