/* .sliderItem{
    display: none;
    opacity: 0;
  }
  .sliderItem.active{
    display: block;
    opacity: 1;
  } */
.mainBanner{
    padding: 30px 0px 0px;
    /* min-height: calc(100vh - 88px); */
}
.mainBanner .control-dots{
    bottom: -20px !important;
}
.bnrBg{
  background-image: url('../../../public/images/mBnrBg.png');
  min-height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  right: -10px;
  display: flex;
    align-items: center;
    justify-content: center;

}
.playControl{
  position: absolute;
  width: 70px;
  height: 70px;
 cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;

}
.bnrBg img{
  width: 100%;
  max-width: 500px;
  border-radius: 25px;
  margin-right: 35px;

}
.mainBanner .control-dots .dot{
    width: 12px;
    height: 12px;
  
    background-color: #D9D9D9;
    margin: 5px;
    text-align: center;
    font-size: 0px;
    border-radius: 100%;
    border: none;
}
.mainBanner .control-dots .dot.selected{
    background-color: #5564AC;
}

  /* .sliderIndicator{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sliderIndicatorItem{
    width: 12px;
      height: 12px;
      display: block;
      background-color: #D9D9D9;
      margin: 5px;
      text-align: center;
      font-size: 0px;
      border-radius: 100%;
      border: none;
  
  }
  .sliderIndicatorItem.active{
    background-color: #5564AC;
  
  } */
  .imageBanner{
    width: 120%;
    position: relative;
    /* right: -7px; */
    top: 0;
  }
 
  .bannerContent{
    text-align: left;
    padding-left: 100px;
  }
  .bannerContent h1{
    font-weight: 600;
  }
  .bannerContent p{
    font-weight: 400;
    font-size: 16px;
    color: #373737;
  }
  @media only screen and (max-width: 768px) {
    .bnrBg{
      min-height: auto;
      background-image: unset;
    }
    .mainBanner{
      padding: 15px 0px 0px;
  }
    .bannerContent{
      padding-left: 15px;
      padding-bottom: 15px;
    }
    .bannerContent h1 {
      font-size: 32px;
  }
  .positionRelative{
    padding-left: 30px;
  }

}