.booking-wrapper{
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 30px 15px;
}
.booking-box{
    width: 100%;
    max-width: 700px;
     height: auto;
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    padding: 30px 0px;
}
.form-progress ul{
    display: flex;
    align-items: center;
    justify-content: center;

   
}
.form-progress ul li{
    text-align: center;
    position: relative;
    width: 20.5%;
}
.form-progress ul li span{
font-size: 14px;
color: #9c9a9a;
}
.form-progress ul li .checkIcon{
  width: 25px;
  height: 25px;
  background-color: #9c9a9a;
  margin: 0 auto;
  border-radius: 100%;
}
.form-progress ul li.active .checkIcon{
    background-color: green;
    color: #fff;
}
.form-progress ul li.active span{
    color: green;
    
}
.form-progress ul li .checkIcon img{
    width: 16px;
}
.form-progress ul li::after{
    content: '';
    width: 60%;
    height: 1px;
    background: #BDC4CE;
    position: absolute;
    left: 70%;
    top: 12px;
    z-index: -1;
}
.form-progress ul li:nth-last-child(1)::after{
    width: 0%;
}
.rdp {
    width: 100%;
    margin: 0px !important;
}
.rdp-table{
    width: 100%;
    max-width:unset !important
}
.DayPicker {
    flex-direction: row;
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
.rdp-months{
    display: block !important;
}
.rdp-day{
  display: initial !important;
}
.rdp-caption{
    padding: 0px 39px !important;
    display: none !important;
}
.date-pickup-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.react-datepicker-wrapper{
    border-radius: 10px;
}
.react-datepicker-wrapper input{
    display: none !important;
}
.dateSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 44px;
}
.react-datepicker__triangle{
    transform: translate(49px, 0px) !important;
}
.dateBtn{
    border: none;
    padding: 7px 25px;
    display: flex;
    align-items: center;
    background: #e5e5e5;
    border-radius: 7px;
}
.dateBtn svg{
    margin-right: 5px;
}
.pickedDate{
    font-size: 20px;
    font-weight: 600;
}
.form-box-step-1, .form-box-step-2, .form-box-step-3{
    padding: 25px 0px 0px;
}
.booking-wrapper h1{
    text-align: center;
    margin-bottom: 20px;
    font-size: 35px;
}
.availSlots{
    display: flex;
    align-items: center;
    padding: 25px 15px;
    justify-content: center;
    flex-wrap: wrap;

}
.slot-item{
    padding: 7px 15px;
    border: 1px solid #e5e5e5;
    margin-right: 10px;
    cursor: pointer;
}
.slot-item.active{
    border-color: #C62154;
}
.slot-item input{
    opacity: 0;
    visibility: hidden;
    width: 0;
}
.slot-box{
    padding: 25px 0px;
   
}
.slot-title{
    background: rgb(240 240 240);
    text-align: center;
    padding: 18px 15px;
}
.slot-title h3{
    font-size: 20px;
    padding-left: 5px;
    margin-bottom: 0;
}
.slot-box h3 span{
    font-size: 12px;
}
.noSlot{
    padding-left: 5px;
    font-size: 13px;
    color: red;
}
.actionBtn{
    text-align: center;
    padding: 20px 0px 0px;
}
.actionBtn button{

  background-color: #C62154;
    color: #fff;
    padding: 8px 45px;
    display: inline-block;
    border-radius: 25px;
    border: none;
    font-size: 16px;
}
.actionBtn button.disableBtn{
  background-color: #e5e5e5;
  color: #000;
} 
.br-0{
    border-radius: 0px;
}
.form-box-2{
    max-width: 500px;
    margin: 0 auto;
}
.overview-box table{
   width: 100%;
}

.overview-box table tr th{
    width: 50%;
}
.bookingFinal{
    background: green;
    color: #fff;
    padding: 16px;
    border-radius: 10px; 
    text-align: center;
 
}
.bookingFinal h4{
    font-size: 18px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bookingFinal h4 span{
    margin-left: 5px;
}

.backtohome a{
    background: #e5e5e5;
    padding: 7px 25px;
    display: inline-block;
    margin-top: 15px;
    border-radius: 10px;
    color: #000;
}
