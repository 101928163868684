@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800&display=swap');
body{
  margin: 0;
  padding: 0;
  font-family: 'Kanit', sans-serif;
}
ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
p{
  font-size: 16px;
  font-weight: 300;
  color: #373737;
  font-family: 'Kanit', sans-serif;
}
a{
  text-decoration: none;
}
.mr-0{
  margin-right: 0px !important;
}
.link, .link:hover{
  color: #000000;
  text-decoration: none;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40{
  margin-bottom: 40px;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-0{
  margin-bottom: 0px !important;
}
 .pt-0{
  padding-top: 0px !important;
 }
.pt-40{
  padding-top: 40px;
} 
.pt-50{
  padding-top: 50px;
} 
.pb-50{
  padding-bottom: 50px;
}
.pt-15{
  padding-top: 15px;
} 
.pb-5{
  padding-bottom: 5px;
}
.pb-80{
  padding-bottom: 80px !important;
}
.pl-0{
  padding-left: 0px !important;
}
.mt-15{
  margin-top: 15px;
}
.error-image-wrapper{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}
.error-image-wrapper a{
  background-color: #C62154;
    color: #fff;
    padding: 10px 35px;
    display: inline-block;
    border-radius: 25px;
    border: none;
}
.error-image-wrapper img{
  width: 100%;
}
.imgResponsive{

}
.overH{
  overflow: hidden;
}
.text-left{
  text-align: left !important;
}
.primaryColor{
  color: #434343;
}
.secondaryColor{
  color: #C62154;
}
.positionRelative{
  position: relative;
}
.primaryButton{
  background-color: #445EC4;
  display: inline-block;
  padding: 7px 25px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  border-radius: 30px;
  border: 1px solid #445EC4;
  transition: all 300ms ease-in;
}

.primaryButton:hover{
  background-color: #fff;
  color: #445EC4;
  border: 1px solid #445EC4;
  transform: scale(1.1);
}
.borderButton{
  background-color: #ffff;
  display: inline-block;
  padding: 7px 25px;
  text-decoration: none;
  color: #445EC4;
  font-size: 14px;
  border-radius: 30px;
  border: 1px solid #445EC4;
  transition: all 300ms ease-in;
}
.borderButton.style-2{
  background-color: #fff;
  font-size: 15px;
}
.borderButton:hover{
  transform: scale(1.1);
  background-color: #445EC4;
  color: #fff;
}
.primaryButton2{
  background-color: #445EC4;
  display: inline-block;
  padding: 7px 25px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  border-radius: 30px;
  border: 1px solid #445EC4;
  transition: all 300ms ease-in;
}
.primaryButton2:hover{
  transform: scale(1.1);
}
.headerContainer{
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
}


.fakeLoader{ position:fixed; z-index:10000; background-color:#000617; width:100%; height:100%; left:50%; margin-left:-50%; top:0%; display: flex;
  align-items: center; background-image:url('../../../public/images/tmpl.png'); background-size:50px 50px ; background-position:center center; background-repeat:no-repeat;  }
.fakeLoader .lod-1{ height:70px; width:70px; border:3px solid #e9292a; border-top-color:rgba(0,0,0,0); border-radius:100%; margin-left:auto; margin-right:auto; padding:2px; 
animation:ld1 1s linear infinite; -webkit-animation:ld1 1s linear infinite; -ms-animation:ld1 1s linear infinite; -moz-animation:ld1 1s linear infinite; -o-animation:ld1 1s linear infinite;}
.fakeLoader .lod-2{ height:15px; width:15px; background-color:#e9292a; border-radius:100%; margin-left:auto; margin-right:auto;
position:absolute;}



.fakeLoader2{    top:0%; transition:all 500ms ease-in; height:0px; overflow:hidden;}
/* @-webkit-keyframes ld1 {
0%{ transform:rotate(0deg);}
  100%{ transform:rotate(360deg);}
}
@-webkit-keyframes ld2 {
0%{ transform:rotate(0);}
  100%{ transform:rotate(360deg);}
} */
@keyframes ld1 {
  0%{ transform:rotate(0deg);}
    100%{ transform:rotate(360deg);}
  }
  @keyframes ld2 {
    0%{ transform:rotate(0);}
      100%{ transform:rotate(360deg);}
    }

.loading-2{
  width: 100%;
  max-width: 60px;
  margin: 0 auto;
}
.loading-2 img{
  width: 100%;
}
.data-not-found{
  text-align: center;
}
header{
   padding: 5px 0px; 
  transition: all 300ms ease-in-out;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  padding: 0px !important;
  transition: all 300ms ease-in-out;
  /* background: linear-gradient(225.27deg, #5567B0 4.65%, #AD549F 36.17%, #D03D6B 64.44%); */
}
.is-sticky .brand img{
  width: 110px;
}
.menu{
  display: flex;
  align-items: center;
}
.replyBtn{
  width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background:linear-gradient(225.27deg, #5567B0 4.65%, #AD549F 36.17%, #D03D6B 64.44%);
    border-radius: 100%;
    margin-left: 20px;
}
.navList{
}
.navList li{
  display: inline-block;
}
.navList li a{
  text-decoration: none;
  display: block;
  padding: 7px 8px;
  color: #434343;
  font-size: 16px;
  /* border-bottom: 1.5px solid transparent; */
  margin: 0px 10px;
  transition: all 500ms ease-in-out;
}
.navList li a:after {
  content: '';
  display: block;
  margin: auto;
  height: 2.5px;
  width: 0px;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}
/* .navList li a:hover:after {
  width: 100%;
  background: #C62154;
} */
.navList li a.active:after {
  width: 100%;
  background: #C62154;
}

.navigation{
  display: flex;
  align-items: center;
}
/* .navList li a:hover{
  border-bottom: 1.5px solid #C62154;
} */
.navToggle{
  height: 55px;
  width: 55px;
  border-radius: 100%;
  border: none;
  z-index: 10000;
  background-color: transparent;
  margin-left: 25px;
  display: none;
}
.navToggle span {
  display: block;
  *display: block;
  padding: 0px 0px;
  position: relative;
  width: 30px;
  height: 2px;
  margin: 7px;
  background-color: #434343;
  margin-left: auto;
  margin-right: auto;
  transition: all 300ms ease-in;
}
.navToggle.open{
  background-color: #fff;
  position: fixed;
  right: 25px;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 10%);


}
.navToggle.open span:nth-child(1){
  transform: rotate(45deg);
  top: 2px;
}
.navToggle.open span:nth-child(2) {
  display: none;
}
.navToggle.open span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -7px;
}
/* .navToggle{
  width: 35px;
  height: 35px;
  border: none;
  background-color: transparent;
  margin-left: 25px;
}
.navToggle span{
  display: block;
  padding: 0px 0px;
  position: relative;
  height: 3px;
  background-color: #434343;
  transition: all 400ms ease-out;
  margin: 4px;
  width: 25px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}
.navToggle span:nth-last-child(1){
  margin-bottom: 0px;
}*/
/* .navToggle.open {
  background-color: #fff;
  position: fixed;
   top: 15px; 
right: 20px; 
width: 45px;
height: 45px;
} 
.navToggle{
  width: 45px;
    height: 35px;
  position: relative;
  border-radius: 100%;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-left: 30px;
  z-index: 10000;
}
.navToggle span{
  display: block;
  position: absolute;
  height: 3px;
  width: 30px;
  background: #434343;
  border-radius: 9px;
  opacity: 1;
  left: 8px;
  right: 0;
  margin-top: 6px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.navToggle span:nth-child(1) {
  top: 0px;
}

.navToggle span:nth-child(2) {
  top: 10px;
}

.navToggle span:nth-child(3) {
  top: 20px;
}


.navToggle.open span:nth-child(1) {
  top: 15px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.navToggle.open span:nth-child(2) {
  opacity: 0;

}

.navToggle.open span:nth-child(3) {
  top: 15px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
} */

/* .sliderBox{
  display: flex;
} */
/* .sliderItem{
  width: 100%;
} */

/* .bannerContent h1 {
  overflow: hidden;
  border-right: .15em solid orange; 
  margin: 0 auto;
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}


@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
} */
#gradientPath{
  animation: mymove 5s infinite;
  transition: all 500ms ease;
}
@keyframes mymove {
  0%   {stroke: #e5e5e5;}
  50% {stroke:#e9467a}
  100% {stroke:#C62154}
}
.img-fluid{
  transition: all 500ms ease;
}
.aboutSection{
  width: 100%;
  padding-top: 70px;
}
.pfc img{
  border-radius: 35px;
}
.designEle{
  max-width: 450px;
    position: relative;
    bottom: 73px;
    z-index: -1;
}
.designEle svg{
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 32px;
    stroke-dasharray: 550;
    stroke-dashoffset: -550;
    animation: draw 3.5s ease-in forwards;
}
@keyframes draw {
 to {
  stroke-dashoffset: 0;
 }
}
/* .designEle2 svg{
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 32px;
  stroke-dasharray: 500;
  stroke-dashoffset: -1000;
  animation: draw 3s ease-in forwards infinite;
}
@keyframes draw {
to {
stroke-dashoffset: 0;
}
} */
.aboutContent{
  padding-left: 25px;
}
.aboutContent p{
  font-size: 16px;
  color: #373737;
  font-weight: 300;
}
.aboutContent h1{
  font-size: 38px;
  color: #C62154;
  font-weight: 600;
}
.aboutContent p a{
  color: #C62154;
}
.ourPortfolio{
  width: 100%;
  min-height: 570px;
  background-color: #fff;
  background-image: url('../../../public/images/curve.svg');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 100px 0px;
  margin-top: -60px;
}
.ourPortfolio h1{
  color: #444444;
  font-size: 30px;
}
.DemoSvg{
  width: 100%;
  height: 500px;
  background-color: #e5e5e5;
  background-image: url('../../../public/images/curve.svg');
}
#main{
    fill: none;
    stroke: #CA4084;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    stroke-dasharray: 1900;
    stroke-dashoffset: 1900;
    animation: draw 3.5s ease-in forwards;
    
      
}
@keyframes draw {
  to {
   stroke-dashoffset: 0;
  }
 }

.portfolioIconBox{
  width: 100%;
  max-width: 159px;
  position: relative;
  transition: all 500ms ease;
}
.portfolioIcons{
  display: flex;
  justify-content: space-between;
  position: relative;
}

.iconWrapper {
  width: 70px;
  height: 70px;
  display: flex;
  /* background-color: #b0b0b0; */
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 10px;
}
.iconWrapper{
  transition: all 300ms ease-in;
  /* filter: grayscale(30%); */
}
.iconWrapper img{
  width: 38px;
  height: 38px;
}
.portfolioIconBox p{
  font-size: 14px;
  font-weight: 400;
}
.pf-1{
  top: 166px;
  left: 55px;
}
.pf-1 .iconWrapper {
  background-color: #CA4084;

}

.portfolioIconBox:hover .iconWrapper{
  /* transform: rotate(360deg);
  transform: scale(1.2); */

  /* transform         : rotate(360deg) scale(1.1);  */
  transform: scale(1.3); 
  filter: grayscale(0%);
}
.pf-2{
  top: 76px;
  left: 50px;
}
.pf-2 .iconWrapper {
  background-color: #86E3CE;
}
.pf-3{
  top: 157px;
  left: 40px;
}
.pf-3 .iconWrapper {
  background-color: #CA4084;
}
.pf-4{
  top: -82px;
}
.pf-4 .iconWrapper {
  background-color: #86E3CE;
}

.ourLearnings, .latestPost{
  width: 100%;
  height: auto;
  padding: 50px 0px;
}
.latestPost{
  background: #fbfbfb;
}
.ourLearnings h1, .latestPost h1{
  font-size: 38px;
    color: #C62154;
    font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}
.ourLanguages h1{
  font-size: 38px;
    color:#C62154;
    font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}
.latestPostItems{
  min-height: 334px;
}
.learningItems, .latestPostItems{
   display: flex;
   align-items: center;
   background: #FFFFFF;
   box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.03), 4px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
   padding: 10px;
   margin-bottom: 25px;
   transition: all 300ms ease-in;
}


.latestPostItems{
   flex-direction: column;
}
.learningItemsThumb, .latestPostItemsThumb{
  position: relative;
  overflow: hidden;
  border-radius: 5px;
 
}

.learningItemsThumb img, .latestPostItemsThumb img{
  width: 100%;
  transition: all 300ms ease-in;

}
.learningItems:hover .learningItemsThumb img{
  transform: scale(1.2);
}
.latestPostItems:hover .latestPostItemsThumb img{
  transform: scale(1.2);
}
.learningItems:hover, .latestPostItems:hover{
  /* transform: scale(1.2); */
  box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.02), 4px 4px 18px rgba(0, 0, 0, 0.2);
}
.learningItemsContent{
  margin-left: -6px;
  z-index: 1;
}
.latestPostItemsContent{
  padding: 8px;
}
.learningItemsContent .location{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.learningItemsContent .location p{
  margin-bottom: 0;
  margin-left: 5px;
}
.blur{
 width: 50px;
 height: 100%;
 position:absolute;
 /* backdrop-filter: blur(1px); */
 /* background-color: #fff; */
 background: linear-gradient(270deg, #FFFFFF 31.61%, rgba(255, 255, 255, 0) 93.08%);
 top: 0;
 right: 0;
}
.learningItemsContent h3{
  font-size: 24px;
  color: #444444;
}
.latestPostItemsContent h3{
  font-size: 20px;
  color: #444444;
}
.post_author p{
  margin-bottom: 0px;
}
.post_author{
  /* display: flex;
  align-items: center; */
}
.post_author p:nth-child(1){
  font-size: 15px;
  color: #c52053;
  font-weight: 400;
}
.post_author p:nth-child(2){
  font-size: 12px;
  margin-bottom: 10px;

}
.ourLanguages{
  width: 100%;
  /* height: 400px; */
  /* background: linear-gradient(225.27deg, #5567B0 4.65%, #AD549F 36.17%, #D03D6B 64.44%); */
  background-color: #fbfbfb;
border-radius: 0px;
padding: 55px 0px;
margin-top: -83px;
}
.programText{
  background: #ffffff;
  padding: 21px;
  border-radius: 10px;
  box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.03), 4px 4px 4px rgba(0, 0, 0, 0.02);
}
.programText img{
  max-width: 50px;
}
.languageText{
  padding-right: 30px;
}
.languageText p, .programText p{
  color: #434343;
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 15px;
}
.program-text-header{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.program-text-header h3{
  color: #434343;
  font-size: 32px;
  font-weight: 500;
  margin-left: 15px;
  margin-bottom: 0px;
}
.languageText h3{
  color: #fff;
  font-size: 49px;
  font-weight: 700;
}
.languageText h4{
  color: #fff;
  font-size: 35px;
  font-weight: 600;
}
.ourLanguages .languageImage{
  border-radius: 10px;
  overflow: hidden;
}
.languageImage img{
  width: 100%;

}
.ourLanguages2{
  width: 100%;
  /* height: 400px; */
  margin-top: -20px;
  /* margin-top: 160px; */
  padding-top: 0px;
  background: linear-gradient(225.27deg, #5567B0 4.65%, #AD549F 36.17%, #D03D6B 64.44%);
}
.designEle2 {
  position: relative;
  top: -14px;
  z-index: -1;
  left: -13px;
  height: 206px;
}
.ourLanguages2 .languageImage{
  top: -105px;
  position: relative;
  border-radius: 10px;
    overflow: hidden;
}
.ourLanguages2 .languageText{
  padding-left: 30px;
  padding-top: 55px;
  padding-right: 0px;
}
.ourLanguages2 .languageText ul{
  list-style: disc;
    padding-left: 18px;
    color: #fff;
    font-weight: 200;
    margin-bottom: 5px;
    font-size: 15px;

}
.video-testimonial{
  width: 100%;
  padding: 0px 0px 100px;
}
.video-title {
  width: 100%;
  text-align: center;
}
.video-title h1{
    font-size: 37px;
    color: #C62154;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}
.video-testimonial-inner{
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  /* overflow: hidden; */
}
.video-testimonial-inner img{
  width: 100%;
  border-radius: 20px;
}
.video-testimonial-inner .playControl{
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  background: #00000030;
  border-radius: 100%;
}
.video-testimonial-inner .playControl svg{
  width: 25px;
  margin-left: 7px;
}


.dgre1{
    max-width: 300px;
    position: absolute;
    left: -126px;
    z-index: -1;
    bottom: -60px;
}
.dgre2{
  max-width: 200px;
  position: absolute;
  bottom: 0;
  right: -100px;
  top: 30px;
  z-index: -1;
}
.contain-video{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.internship{
  width: 100%;
  height: auto;
  background-image: url('../../../public//images/internship.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 50px;
}
.internship h1{
  font-size: 40px;
    color: #C62154;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}
.internshipList{
  display: flex;
  justify-content: center;
  align-items: center;
}
.internshipItem{
  width: 100%;
  max-width: 300px;
  height: 300px;
  background-color: #fff;
  margin: 50px 110px;
  border-radius: 20px;
  position: relative;
 
  overflow: hidden;
}
.internshipItem .bgImg{
  width: 100%;
  border-radius: 15px;
  transition: all 300ms ease-in;
}
.overlayContent{
  width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
    background: rgba(255,255,255,0.5);
}
.overlayContent p{
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  color: #1B215C;
  text-transform: uppercase;
}
.internshipItem:hover .bgImg{
  transform: scale(1.5);
}

.testimonial{
  padding: 50px 0px;
  position: relative;
}
.testimonial h1{
  font-size: 38px;
  color: #C62154;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
.designEle3{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.career-section{
  text-align: center;
  width: 100%;
  padding: 50px 15px;
  background: #f7f7f77a;
  /* border-right: 7px solid #6863ac;
  border-left: 7px solid #d03d6b; */
}
.career-section.style-2{
  padding: 40px 15px;
}


.career-inner{
  max-width: 700px;
  margin: 0 auto;
}
.career-inner h3{
  color: #4d4d4d;
  margin-bottom: 15px;
}
.career-section.style-2 h1{
   font-size: 37px;
   font-weight: 600;
   max-width: 670px;
   margin: 0px auto;

}
.faq{
  padding-top: 50px;
  padding-bottom: 50px;
}
.faq h1{
  font-size: 38px;
  color: #C62154;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
  max-width: 698px;
  margin: 0 auto;
}

.accordionWrapper{
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding: 15px 15px 0px;
}
.footerTop{
  width: 100%;
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(225.27deg, #5567B0 4.65%, #AD549F 36.17%, #D03D6B 64.44%);
}
.footerTop p{
  margin-bottom: 0px;
  color: #fff;
  margin-right: 25px;
  font-size: 18px;
}
.footerTop a{
  display: flex;
  align-items: center;
  padding: 6px 25px;
  background-color: #25D366 ;
  color: #fff;
  border-radius: 25px;
  font-size: 18px;
  transition: all 300ms ease-in;
  border: 1px solid transparent;
}
.footerTop a svg{
  margin-right: 5px;
}
.footerTop a:hover{
  transform: scale(1.1);
}
.footerSocials{
  margin-left: -12px;
}
.footerSocials li{
  display: inline-block;
  margin-right: 5px;
  
}
.footerSocials li:nth-last-child(1){
  margin-right: 0px;
}
.footerSocials li a{
  display: flex;
  width: 40px;
  height: 40px;
  /* background-color: #C62154; */
  line-height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #C62154;
  transition: all 300ms ease-in;
}
.footerSocials li a:hover{
  background-color: #C62154; 
  color: #fff;
}
.footerMiddle{
  width: 100%;
  margin: 0 auto;
  padding: 30px 15px 20px;
}
.footerWidget1 , .footerWidget2{
  padding: 0px 30px;
}
.footerWidget1 p{
  font-size: 16px;
  font-weight: 400;
}
.footerWidget2 h3{
   font-size: 18px;
}
.footerWidget2 p{
  margin-bottom: 5px;
  font-weight: 300;
}
.footerWidget2 p a{
  color: #373737;
}
.footerWidget3{
  text-align: center;
}
.footerWidget3 img{
  width: auto;
  height: 300px;
}
.footerBottom {
  padding: 16px;
    text-align: center;
    border-top: 1px solid #D9D9D9;
}
.footerBottom p{
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 15px;
}
.footerBottom p a{
  color: #373737;
}

.whatsApp{
  position: fixed;
  bottom: 10px;
  right: 15px;
}
.whatsApp a{
  width: 50px;
  height: 50px;
  background-color: #25D366;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
/*********Inner Page Css***********/
.innerPageBanner{
  position: relative;
}

.page-content{
  position: relative;
}

.page-content2{
  padding: 20px 0px 50px;
  position: relative;
}
.innerDesignEle {
  position: absolute;
  top: -110px;
  left: 200px;
  z-index: -1;
}
.innerDesignEle svg{
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 32px;
  stroke-dasharray: 550;
  stroke-dashoffset: -550;
  animation: draw 2s ease-in forwards;
}
@keyframes draw {
to {
stroke-dashoffset: 0;
}
}
.innerPageBanner h1{
  font-size: 33px;
  font-weight: 600;
  color: #C62154;
  padding-left: 10px;
}
.bannerImg{
  border-radius: 35px;
  overflow: hidden;
  filter: drop-shadow(10px 10px 25px rgba(175, 188, 248, 0.45));
}
.bannerImg2{
  border-radius: 23px;
  overflow: hidden;
  filter: drop-shadow(10px 10px 25px rgba(175, 188, 248, 0.45));
}
.page-banner{
  background-color: #f8f8f8;
  padding: 50px 0px;
}
.page-banner-content span{
  margin-bottom: 5px;
  display: block;
  font-size: 20px;
  font-weight: 600;
}
.page-banner-content span::after{
  content: '';
  display: inline-block;
  height: 1.5px;
  width: 100px;
  width: 100px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-color: #373737;
}
.page-banner-content h4{
  font-weight: 300;
  font-size: 26px;
  line-height: 35px;
  color:#373737;


}
.page-banner-img img{
  width: 100%;
}
.inner-img-box{
  /* max-width: 400px;
  margin: 0 auto; */
  text-align: center;
}
.inner-content-box {
  padding: 25px;
}
.inner-content-box h3{
  font-weight: 400;
  color: #d03d6b;
  font-size: 24px;
}
.inner-img-box img{
  width: 100%;

}
.inner-content-box p strong{
  font-size: 22px;
  font-weight: 300;

}
.inner-page-section{
  padding: 30px 0px;
}
.title-3{
  text-transform: uppercase;
  color: #C62154;
}
.meet-our-team{
  width: 100%;
  position: relative;
  padding: 20px 0px;
}
.meet-our-team h1{
  color: #C62154; 
  text-align: center;
  margin-bottom: 25px;
}
.team-item{
  background-color: #fff;
  text-align: center;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.team-item-inner{
    width: 100%;
    background-color: #EDE7FB;
    margin: 0 auto;
    position: relative;
    padding: 15px;
    border-radius: 15px;
}
.team-thumb{
  position: relative;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 5px 5px 25px rgba(237, 231, 251, 0.5);
}

.team-thumb img{
  width: 100%;
}


.team-socials ul li{
  display: inline-block;
}

.team-socials ul li a{
   width: 38px;
   height: 38px;
   border: 1px solid #C62154;
   border-radius: 100%;
   display: flex;
   margin: 10px;
   align-items: center;
   justify-content: center; 
}

.team-front{
  width: 100%;
  transition: .6s;
  backface-visibility: hidden;
  transform: rotateY(0);
}
.team-back{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: .6s;
  backface-visibility: hidden;
  transform: rotateY(-180deg);
  transform-style: preserve-3d;
  transition-property: transform,-webkit-transform;
}
.team-item:hover .team-front {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.team-item:hover .team-back {
-webkit-transform: rotateY(0);
transform: rotateY(0);
}
.team-modal-img{
  max-width: 250px;
  margin: 0 auto;
}
  .error-help{
    color: #C62154;
    text-align: left;
    display: block;
    font-size: 15px;
  }
  .noticeMsg{
    font-size: 12px;
    color: #787878;
  }
  .brand-section h4{
    margin-bottom: 20px;
    color: #C62154;
    text-align: center;
  }
  .brand-section .react-multi-carousel-list{
    padding-bottom: 0px;
  }
  .brand-section{
    padding: 25px 0px;
    /* background-color: #e5e5e526; */
  }
  .logo-items {
    background-color: #fff;
    border: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.03), 4px 4px 4px rgba(0, 0, 0, 0.02);
  }
/*********Faq********/
.searchbar{
  max-width: 370px;
  margin: 0px auto 30px;
  position: relative;
  /* display: flex;
  align-items: center; */

}
.searchbar input{
width: 100%;
  height: 45px;
  padding: 7px 65px 7px 25px;
  border-radius: 25px;
  border: 1px solid #434343;
  color: #434343;
  transition: all 500ms ease-in-out;
}
.searchbar input:focus{
  outline: none;
  /* border: 1px solid #C62154; */
}
.searchbar button{
  width: 65px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;

}
.searchbar button:focus{
  outline: none;
}
.footerDesign{
  position: absolute;
  right: 0;
  bottom: -35px;
  z-index: -1;
}
.footerDesign svg{
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 32px;
  stroke-dasharray: 550;
  stroke-dashoffset: -550;
  animation: draw1 3.5s ease-in forwards;
}
@keyframes draw1 {
to {
stroke-dashoffset: 0;
}
}
.serviceItems{
  margin-bottom: 25px;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 20px;
  overflow: hidden;
  transition: all 500ms ease-in;
}
.serviceItems:hover{
  box-shadow: -4px -4px 4px rgb(0 0 0 / 2%), 4px 4px 18px rgb(0 0 0 / 20%);
}
.serviceItems:hover img{
  transform: scale(1.1);
}
.serviceOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50.52%, rgba(255, 255, 255, 0.740774) 72.4%, rgba(255, 255, 255, 0.935714) 81.25%, #FFFFFF 93.75%);
}
.serviceItemsContent{
  z-index: 1;
  position: absolute;
    bottom: 20px;
    left: 35px;
    padding-right: 50px;
}
.serviceItemsContent h3{
    color: #445EC4;
    font-size: 22px;
    margin-bottom: 0px;
}
.serviceItemsContent p{
  line-height: 16px;
}

/*******Modal Content********/
.userInfo{
  display: flex;
  align-items: center;
}
.userIcon {
      width: 70px !important;
      height: 70px;
      border-radius: 100%;
      overflow: hidden;
}
.userIcon img{
  width: 100%;
}
.userDeg h1{
  font-size: 18px;
}
.userDeg p{
  margin-bottom: 0;
  color: #D03D6B;
}

/*********Contact Page*********/
.contactForm{
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.03), 4px 4px 12px rgba(0, 0, 0, 0.03);
backdrop-filter: blur(5px);
border-radius: 30px;
text-align: center;
}
.contactBox{
  position: relative;
  margin-bottom: 50px;
}

.form-group{
  margin-bottom: 15px;
}
.form-group label{
  text-align: left;
  width: 100%;
  padding-left: 5px;
}
.form-control{
  border-radius: 35px;
  background-color: transparent;
  border: 1px solid #434343;
}

.form2 label{
  padding-left: 0px;
  color: #445EC4;
}
.form-control2{
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding: 7px 15px 7px 0px;
  color: #B7B7B7;
}
.form-control2:focus{
  outline: none;
}
.fullWidth{
  width: 100%;
}
.sbtContact{
  border: none;
  padding: 7px 35px;
  background-color: #445EC4;
  color: #fff;
  border-radius: 35px;
  margin: 0 auto;
}
.mobileShowCase{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 4px;
}

.mobileShowCase p{
    margin-bottom: 0px;
    font-size: 20px;
    margin-left: 10px;
}
.contactItem{
  display: flex;
  align-items: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: -4px 0px 12px rgb(0 0 0 / 3%), 4px 4px 12px rgb(0 0 0 / 3%);
  border-radius: 10px;
  margin-bottom: 15px;
}
.contactItem p{
  margin-bottom: 0px;
}
.contactItem p a{
  color: #373737;
  font-size: 15px;
  font-weight: 400;
}
.contactEmails{
  padding-left: 15px;
}

.googleMap{
  padding: 10px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: -4px 0px 12px rgb(0 0 0 / 3%), 4px 4px 12px rgb(0 0 0 / 3%);
  border-radius: 10px;
}
.googleMap iframe{
  border: none;
}
.seperator{
  margin-top: 15px;
  color: #445ec4
}


/**********Testimonial**********/
/* .testimonialList{
  display: flex;
}*/
.testimonialList li{
  text-align: unset !important;
} 
.testimonialItem{
  background-color: #E8ECFD;
  padding: 30px;
  border-radius: 25px;
  height: 100%;
}

.aboutUser{
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.userThumb{
  width: 70px !important;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;
}
.aboutUser img{
  width: 100%;

}
.userDetails{
  padding-left: 15px;
}
.userDetails h4 {
  margin-bottom: 2px;
  font-size: 18px;
}
.userDetails p, .rdm{
  margin-bottom: 0px;
  color: #D03D6B;
}


.carousel-item-mr-40-px{
  padding: 10px;
}
.react-multi-carousel-list{
  padding-bottom: 40px;
}
.rdm{
  color: #D03D6B;
  background-color: transparent;
  border: none;
}

.userDeg{
  padding-left: 10px;
}
.react-multi-carousel-dot button{
  border: none !important;
  background-color: #D9D9D9 !important;
}
.react-multi-carousel-dot--active button {
  background: #5564AC !important;
}
.testimonialList{
  position: relative;
  padding-bottom: 50px;
}
.designEle5{
  position: absolute;
  bottom: -175px;
  right: -10px;
  z-index: -1;
}
.designEle5 svg{
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 32px;
  stroke-dasharray: 550;
  stroke-dashoffset: 550;
  animation: draw 2s ease-in forwards;
}
@keyframes draw {
to {
stroke-dashoffset: 0;
}
}
.videoTestimonialItem{
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}
.videoTestimonial h1{
  padding-left: 15px;
  font-size: 35px;
}
.videoThumb img{
  width: 100%;
}
.playBtn{
  position: absolute;
    background-color: transparent;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    right: 0;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.program-title h3{
  font-size: 30px;
  color: #445EC4;
}
.our-program-content{
  width: 90%;
  margin: 0 auto;
}
.our-program-content h4{
  font-size: 18px;
  margin-top: 15px;
  color: #444444;
}
.featureBox ul{
  list-style: disc;
  padding-left: 20px;
  color: #444444;
}
.eligibilityItems ul li{
  display: inline-block;
}
.eligibilityItems ul li a{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  margin: 7px 15px;
  font-size: 14px;
}
.eligibilityItems ul{
  width: 100%;
  box-shadow: -4px -4px 6px rgba(0, 0, 0, 0.05), 4px 4px 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
}

.eligibilityItems ul li a img{
  height: 40px;
  width: auto;
  margin-bottom: 5px;
}
.eligibility, .featureBox {
  margin-bottom: 30px;
}
.eligibility h4{
  margin-bottom: 12px;
}
.stepBox{
  background: #EDE7FB;
  padding: 20px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.stepBox p, .HotelBox p{
  margin-bottom: 0px;
  line-height: 18px;
  font-size: 15px;
}
.stepLabel{
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px 15px;
  border-bottom-left-radius: 23px;
}
.stepBox .stepIconContent img{
  width: auto;
  height: 50px;
  margin-bottom: 5px;
}
.processList h4{
  margin-bottom: 10px;
}
.hotelsList{
  margin-top: 25px;
}
.hotelsList h4, .processList h4{
  color: #445EC4;
  font-size: 24px;
  font-weight: 600;
}

.HotelBox{
  background-color: #EDE7FB;
  padding: 20px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
}

.hotelBoxImage{
  width: 50px;
  height: 50px;
  margin: 0 auto 7px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

}
.programList{
  margin-bottom: 25px;
}
.programList h1{
  font-size: 35px;
  margin-bottom: 15px;
  color: #445EC4;
}
.programItems{
  margin-bottom: 25px;
  text-align: center;
  background: rgba(217, 217, 217, 0.1);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  overflow: hidden;
  transition: all 500ms ease-in;
}
.programItems:hover{
  /* box-shadow: 0 0 11px rgba(33,33,33,.2);  */
  transform: scale(1.1);
}

.programItems h3{
  font-size: 20px;
  font-weight: 500;
  color: #D03D6B;
  padding: 10px 0px;
}

.programItems img{
  width: 100%;
  height: auto;
  transition: all 500ms ease-in;
}
.programItems.active h3{
  color: #fff;
}
.programItems.active {
  background-color: #D03D6B;
}
.map {
  /* -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
   filter: grayscale(100%); */
   margin-top: -68px;
}

.uploadCv{
  text-align: center;
  padding: 35px 15px 15px;
}
.uploadCv button{
  background-color: #C62154;
  color: #fff;
  padding: 10px 35px;
  display: inline-block;
  border-radius: 25px;
  border: none;
}
.ViewMoreBox{
  text-align: center;
}
.ViewMoreBox1{
  text-align: center;
  padding-top: 15px;
}

.langPageContent{
  width: 90%;
  margin: 0 auto;
}
.langPageContent p{
  margin-bottom: 15px;
}

.lang-title{
  font-size: 35px;
  font-weight: 500;
  color: #445EC4;
  margin-top: 15px;
}
.features-list {
  list-style: disc;
  padding-left: 22px;
  margin-bottom: 1rem;
}
.features-list li{
  font-weight: 300;
}
.week-inner-box{
  background: rgba(217, 217, 217, 0.15);
  border-radius: 24px;
  padding: 25px;
}
.week-label{
  display: flex;
  align-items: center;
}
.week-label span{
  width: 5px;
  height: 20px;
  background-color: #445ec4;
  display: inline-block;
  margin-right: 5px;
}
.week-label h4{
  margin-bottom: 0;
}
.access-btn{
  padding: 15px 0px 0px;
  text-align: center;
 
}
.take-challenge-box{
  text-align: center;
  padding: 30px 15px;
  border-radius: 15px;
  background: linear-gradient(91.21deg, rgba(208, 61, 107, 0.042) 18.02%, rgba(68, 94, 196, 0.1) 89.93%);
}
.access-btn button, .take-challenge-box button{
  background-color: #C62154;
    color: #fff;
    padding: 10px 35px;
    display: inline-block;
    border-radius: 25px;
    border: none;
}
.meet-founder{
  padding-top: 50px;
}
.meet-inner-box{
  background: rgba(217, 217, 217, 0.2);
border-radius: 24px;
padding: 30px;
}
.fd-img{
   width: 100%;
   text-align: center;
}
.fd-img img{
  width: 100%;
  height: auto;
}
.founder-details h4 {
  margin-bottom: 2px;
  font-size: 25px;
}
.founder-details p {
  margin-bottom: 0px;
  color: #D03D6B;
  font-weight: 500;
}

.user-testimonials{
  padding-top: 50px;
}
.user-testimonials h4{
  margin-bottom: 20px;
}

.testimonial-item{
  display: flex;
  align-items:flex-start;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.user-tm-details{
  text-align: left;
}
.user-message p{
  margin-bottom: 0px;
}
.tm-user-details h4, .tm-user-details p{
   margin-bottom: 0px;
}
.tm-user-details h4{
  margin-top: 15px;
  color: #445EC4;
}
.user-tm-thumb{
  min-width: 160px;
  min-height: 160px;
  border-bottom-right-radius: 98px;
  background-color: #fff;
  border-bottom-left-radius: 98px;
  border-top-left-radius: 98px;
  overflow: hidden;
  text-align: center;
}
.userMessage p:nth-last-child(1), .userMessage .userWords p:nth-last-child(1){
  margin-bottom: 0px;
}

.user-tm-thumb img{
   width: 100%;
}
.userWords{
  max-height: 150px;
  overflow: hidden;
}
.user-tm-details{
  padding: 0px 15px;
}
.userBox{
  display: flex;
  align-items: center;
}

.userSocialsBox {
  position: relative;
}
.userSocialsBox button{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.5);
  border: none;
  border-radius: 100%;
  position: absolute;
  right: 0;

}
.userSocials{
  margin-right: 45px;
  margin-top: 4px;
  display: none;
}
/* .userSocialsBox button:hover .userSocials{
  display: block;
} */
.userSocialsOuter{
  display: flex;
  align-items: center;
}
.userSocialsOuter .userSocialsBox{
  margin-right: 10px;
}
.userSocials.show{
  display: block;
}
.userSocials li{
  display: inline-block;
  margin-right: 10px;
}
.userSocials li:nth-last-child(1){
  margin-right: 0px;
}
.userSocials li a{
  display: flex;
  width: 35px;
  height: 35px;
  border: 1px solid #D03D6B;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
/* .carousel.carousel-slider .control-arrow{
  background-color: red !important;
  opacity: 1 !important;
} */

.left-arrow svg, .right-arrow svg{
  color: #445EC4 !important;
  font-size: 25px;
}

.left-arrow, .right-arrow {
  background:#e5e5e5;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content:center;
  border-radius: 100%;
  cursor: pointer;
}

.left-arrow{
  position: absolute;
  top: calc(50% - 40px);
  z-index: 1;
}

.right-arrow{
  position: absolute;
  top: calc(50% - 40px);
  z-index: 1;
  right: 0;
}
.popup-form .mobileShowCase p{
  font-size: 25px;
   color: #C62154;
   font-weight: 400;
}

.video-box{
    width: 100%;
    max-width: 500px;
    margin: 25px auto 50px;
    background: red;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-box img{
  width: 100%;
}

/**********Blog css********/
.blogItems{
  background-color: #fff;
  margin-bottom: 25px;
  padding: 5px;
  box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.03), 4px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
}
.blogThumb{
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.blogThumb img{
  width: 100%;
}
.author_details{
  width: 100%;
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  bottom: 0;
  padding: 7px 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.author_details h6{
  margin-bottom: 0px;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
}
.author_details p{
  margin-bottom: 0px;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
}
.blogTitle{
  padding-left: 5px;
  padding-top: 10px;
}
.blogTitle h3{
  font-size: 20px;
  margin-bottom: 0;
  color: #444444;
  font-weight: 400;
}
.post-title{
  line-height: 42px;
  font-size: 40px;
  margin-top: 15px;
}
.blog-featured-image{
  width: 100%;
  position: relative;
  border-radius: 28px;
  overflow: hidden;
}
.blog-featured-image img{
  width: 100%;
}

.blog-featured-image .author_details h6{
  margin-bottom: 0px;
  color: #fff;
  font-weight: 400;
  font-size: 17px;
}
.blog-featured-image .author_details p{
  margin-bottom: 0px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
}
.latest-post .blogItems{
  padding: 0px;
}

.latest-post .blogThumb{
  border-radius: 0px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.latest-post .blogTitle{
  padding: 10px;
}
.latest-post .blogTitle h3{
  font-size: 15px;
}
.latest-post .author_details h6{
 font-size: 14px;
}
.latest-post .author_details p{
  font-size: 10px;
 }

.enqForm{
  padding: 15px;
}
.enqForm button{
background-color: #C62154;
    color: #fff;
    padding: 8px 45px;
    display: inline-block;
    border-radius: 25px;
    border: none;
    font-size: 16px;

}
 .form-title{
    margin: 0;
    font-size: 25px;
 }
 .btn-block{
  width: 100%;
 }
 .pop-img{
  width: 100%;
  width: 100%;
 }
 .plr{
  position: relative;
 }
 .md-btn-2{
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
    background-color: #e5e5e5;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
 }
.book-btn{
  background-color: #fff;
  color: #C62154;
  padding: 7px 15px;
  display: inline-block;
  border-radius: 25px;
  border: 1px solid;
  margin-left: 10px;
  transition: all 300ms ease-in;
}
.book-btn:hover{
  background-color: #C62154;
  color: #fff;
  border: 1px solid #C62154;
  transform: scale(1.1);
}
.pioneersSection{
  padding: 50px 0px;
  background: #fbfbfb;
}
.pioneersSectionContent{
  padding: 10px 0px;
}
.pioneersSectionContent h1{
  font-size: 37px;
  font-weight: 600;
  color:#d03d6b;
}
.pioneersSectionContent h1 span{
  color: #434343;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0px;
}
.pioneersSection img{
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
}
.align-center{
  align-items: center;
}
.pioneersSectionContent p{
  font-size: 20px;
  font-weight: 300;
}
.pioneersSectionContent p span{
  font-size: 32px;
  color: #445ec4;
  font-weight: 600;
}
.pioneersSectionContent p:nth-last-child(1){
  margin-bottom: 0px;
}

/*******Why Alzea******/
.patchContainer{
  width: 100%;
  max-width: 600px;
  background: rgba(255, 60, 121, 0.75);
  backdrop-filter: blur(2px);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  
}
.whyAlzContent{
  padding: 25px 20px 25px 120px;
}
.whyAlzContent h1{
  font-size: 30px;
  color: #fff;
}
.whyAlzContent p{
  margin-bottom: 0px;
  font-size: 15px;
  color: #fff;
}
.service-items{
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.03);
  padding: 20px 15px 44px;
  min-height: 393px;
  position: relative;
  transition: all 300ms ease;
  cursor: pointer;
}
.service-items:hover{
  background: rgba(208, 61, 107, 0.15);
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}
.service-items.active{
border-radius: 8px;
background: rgba(208, 61, 107, 0.15);
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}
.service-items img{
  width: 50px;
  margin-bottom: 15px;
}
.service-items h3{
  color: #444;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 25px; 
}
.service-items p{
  color: rgba(68, 68, 68, 0.85);
font-family: Kanit;
font-size: 15px;
font-style: normal;
font-weight: 300;
line-height: 20px
}
.service-items a{
  position: absolute;
  bottom:  15px;
  
}
.our-goal{
  padding: 50px 15px 0px;
}
.our-goal h4{
  color: #D03D6B;
font-family: Kanit;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 20px;
margin-bottom: 15px;
}
