
.modalWrapper {
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 background-color: rgba(0, 0, 0, 0.7);
 width: 100%;
 height: 100%;
 z-index: 1000;
 display: flex;
 align-items: center;
 justify-content: center;
}

.modalContainer {
  max-width: 700px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
   animation: fadeInDown 0.8s;
}
.modalContainer.fullWidth {
  max-width: 825px;
}

@keyframes fadeInDown {
    0%{
        opacity: 0;
        transform: translateY(-70px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}
.modalHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 7px 15px;
}
.modalHeader h1{
    font-size: 20px;
    margin-bottom: 0px;
}
.modalHeader p{
    margin-bottom: 0;
    font-size: 14px;
}
.modalHeader button {
    border: none;
    background-color: #e5e5e5;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}


.modalContent{
    padding: 15px;
    overflow-y: auto;
    max-height: 450px;
}

.modalContent2{
    padding: 20px 30px;
    overflow-y: auto;
    
}